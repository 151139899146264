// Generated by Framer (224f28e)

import { addFonts, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Arc from "https://framerusercontent.com/modules/4S4SnR1GdEzX95H1h5mT/TDb0qbFhtN7SkrM58svb/Arc.js";
const ArcFonts = getFonts(Arc);

const cycleOrder = ["yEODdsn1l"];

const variantClassNames = {yEODdsn1l: "framer-v-105inl5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "yEODdsn1l", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yEODdsn1l", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mq6vF", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-105inl5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yEODdsn1l"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-1tlr4zj-container"} layoutDependency={layoutDependency} layoutId={"fiIEezwuc-container"} transition={transition}><Arc alignmentBaseline={"bottom"} animate={false} animateDuration={5} animateEnd={150} animateStart={-50} color={"rgb(255, 255, 255)"} cylinderHeight={0} flip={false} font={{font: "Inter", fontSize: 9, fontWeight: 600, letterSpacing: 2, wordSpacing: 6}} height={"100%"} id={"fiIEezwuc"} layoutId={"fiIEezwuc"} rotate rotateSpeed={5} slots={[]} startOffset={0} style={{height: "100%", width: "100%"}} text={"✦ VIEW CASE STUDY ✦ VIEW CASE STUDY"} type={"circle"} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mq6vF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mq6vF .framer-1smbvn5 { display: block; }", ".framer-mq6vF .framer-105inl5 { height: 112px; overflow: hidden; position: relative; width: 112px; }", ".framer-mq6vF .framer-1tlr4zj-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 112
 * @framerIntrinsicWidth 112
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerhWImAogo2: React.ComponentType<Props> = withCSS(Component, css, "framer-mq6vF") as typeof Component;
export default FramerhWImAogo2;

FramerhWImAogo2.displayName = "arcc";

FramerhWImAogo2.defaultProps = {height: 112, width: 112};

addFonts(FramerhWImAogo2, [...ArcFonts])